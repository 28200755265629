<template>
  <div class="container">
    <common-shell type="meterRate"></common-shell>
  </div>
</template>

<script>
import CommonShell from "../comps/CommonShell.vue";

export default {
  name: "MeterRate",
  components: { CommonShell },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
}
</style>
